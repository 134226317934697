// libraries
import { Link, useRouteMatch } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// functions & components
import { apiClient } from '../../../core/api_client';
import l from '../../../core/localize';
import { Routing } from '../../../core/routing';

// assets
import announced from '../../../../assets/img/announced.svg';
import loc from '../../../../assets/img/projects/location.svg'

export const getLink = id => {
    switch (id) {
        case 100001:
            return "https://yasamal2.mida.gov.az/";
        case 1:
            return "https://gence.mida.gov.az/";
        case 300001:
            return "https://hovsan.mida.gov.az/";
        case 200002:
            return "https://sumqayit.mida.gov.az/";
        case 300005:
            return "https://hovsan2.mida.gov.az/";
        default:
            return "/layihe/" + id;
    }
};

const Projects = () => {
    const match = useRouteMatch();
    const { categoryId } = match.params;

    const [projects, setProjects] = useState([]);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState();
    const [categories, setCategories] = useState([]);
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        apiClient.get('/projectPlans' + (!categoryId ? "" : `?project_category_id=${categoryId}`))
            .then(res => {
                // setProjects(res.data);
                setLoading(false);
                setProjects(res.data.filter(i => i.id !== 300016 && i.id !== 300017 && i.id !== 300018));
            });
    }, [categoryId]);

    useEffect(() => {
        const bodyElement = document.body;
        if (bodyElement) {
            if (isLoading) {
                bodyElement.style.overflow = 'hidden';
            } else {
                bodyElement.style.overflow = 'visible';
            }
        }
    }, [isLoading]);

    useEffect(() => {
        apiClient.get('/cities').then(res => setCities(res.data));
    }, []);

    useEffect(() => {
        apiClient.get('/projectCategories').then(res => setCategories(res.data));
    }, []);

    const handleSort = cityId => {
        console.log(cityId)
        setProjects(projects.filter(proj => proj.city_id === cityId || cityId === -1))
        setCity(cityId ? cities.find(c => c.id === cityId) : null)
    };

    const getLabel = id => {
        switch (id) {
            case 100001:
            case 1:
            case 300005:
                return false;
            default:
                return false;
        }
    }

    return (
        <>
            {isLoading &&
                <div className='d-flex justify-content-center' style={{ height: window.innerHeight }}><div className="lds-roller" style={{ top: 200 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
            {projects &&
                <div className="block-bg1 pt-md-3 pt-4 py-1">
                    <div className='breadcrumb page-link'>
                        <div className='line'></div>
                        <div className='container'>
                            <div className='bc-items px-3 d-flex justify-content-center '>
                                <Link to="/layiheler" className={"bc-button " + (!categoryId ? " bc-button-active" : "")}>{t('projects:all_projects')}</Link>
                                {categories.map(category => {
                                    const style = categoryId === category.id ? "bc-button-active" : category.style;
                                    return (
                                        <Link to={`/layiheler/${category.id}`} className={`bc-button ${style}`} key={category.id}>{l(category, "name", currentLang)}</Link>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='container mt-5 mb-5'>
                        <div className='proj-buttons d-flex justify-content-between'>
                            <h5 className='t-color1 fw-bolder' style={{ fontSize: "22px" }}>{t('projects:projects_list')}</h5>
                            <div className='d-flex'>
                                <div className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                        {city ? l(city, "name", currentLang) + " şəhəri" : t('projects:sort_by_city')}
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-item" onClick={_ => handleSort(-1)}>{t('projects:all_cities')}</li>
                                        {cities.map(city =>
                                            <li className="dropdown-item" onClick={_ => handleSort(city.id)} key={city.id}>{l(city, "name", currentLang)} şəhəri</li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="projects" className="row mt-2 mb-3 gx-5">
                            {projects.map(item =>
                                <a href={getLink(item.id)} target='_blank' rel='noreferrer' className='project-item col-lg-6 col-md-6 col-sm-12 col-12 my-3 position-relative' key={item.id}>
                                    <div className='img'>
                                        <img className='proj-pic' src={Routing.generateFileRoute(item.thumbnail_file)} alt={l(item, "name", currentLang)} />
                                    </div>
                                    {getLabel(item.id) &&
                                        <img className='position-absolute status-label-flag-proj' style={{ top: '7px', right: '-9.3px', width: '103px', maxWidth: '103px' }} src={announced} alt="{t('common:announced')}" />}
                                    <p className='mt-3 mb-1'>{l(item, "name", currentLang)}</p>
                                    <small><img src={loc} className="me-1" alt='' />{l(item, "address", currentLang)}</small>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Projects;
